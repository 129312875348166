<template>
  <v-row no-gutters dense class="ma-0 pa-0 mb-n12">
    <v-col cols="12" class="mb-2 d-flex flex-row">
      <v-btn icon class="mr-2" @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <div v-if="segment && segment.id" class="title">
        <span v-if="segment.mocktest">{{ segment.mocktest.title }}</span>
        <span class="grey--text">&nbsp;•&nbsp;</span>
        {{ segment.title }}
      </div>
      <v-spacer></v-spacer>
      <v-select
        style="max-width: 140px"
        dense
        single-line
        v-model="colViewMode"
        :items="$store.getters['dashboard/grid/colViewModeItems']"
        label="Column View Mode"
      />
    </v-col>
    <v-col cols="12" class="white ma-0 pa-0">
      <v-divider></v-divider>
      <vue-editable-grid
        tab-index="1"
        class="grid"
        ref="grid"
        id="grid"
        :column-defs="columnDefs"
        :row-data="rows"
        :only-border="true"
        :enable-filters="false"
        :breakLine="false"
        :breakLineWordLimit="200"
        :breakLineEllipsis="'...'"
        :tab2Column="false"
        :userReservedKeys="userReservedKeys"
        row-data-key="uid"
        :itemHeight="fontSize * 2"
        :style="gridStyle"
        @cell-updated="cellUpdated"
        @row-selected="rowSelected"
        @context-menu="onContextMenu"
        @contextmenu="onContextMenu"
        @keyup="onKeyUp"
        tabindex="0"
      >
        <template v-slot:header>
          <div class="mb-4">
            <v-btn class="black--text" icon @click="action_addRow">
              <v-icon>mdi-plus</v-icon>
            </v-btn>

            <v-btn
              class="black--text"
              icon
              @click="showLatexEditor = !showLatexEditor"
            >
              <v-icon>mdi-math-compass</v-icon>
            </v-btn>

            <v-btn
              class="black--text"
              v-if="selectedRow"
              icon
              @click="showPreview = !showPreview"
            >
              <v-icon>mdi-monitor-cellphone</v-icon>
            </v-btn>
            <v-btn
              class="black--text"
              :loading="loading"
              icon
              @click="action_refresh"
            >
              <v-icon>mdi-reload</v-icon>
            </v-btn>

            <v-btn
              :disabled="
                !$store.getters['dashboard/grid/historyRows'] ||
                !$store.getters['dashboard/grid/historyRows'].length
              "
              :loading="loading"
              icon
              @click="action_undoRemoveRow"
            >
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:header-r>
          <div class="mb-4 mr-2">
            <v-btn
              small
              :loading="loading"
              class="primary white--text"
              @click="action_publish"
            >
              <v-icon left>mdi-floppy</v-icon>
              {{ $lang("SAVE ALL") }}
            </v-btn>
          </div>
        </template>
      </vue-editable-grid>
    </v-col>
    <WysiwygEditorDialog
      :show="showWysiwygEditor"
      @opened="
        (trueFalse) => {
          showWysiwygEditor = trueFalse;
        }
      "
    >
      <template v-slot:header>
        Edit {{ selectedRow && selectedRow.is_latex ? "(Has Latex)" : "" }}
      </template>
      <template v-slot:default>
        <v-row no-gutters>
          <v-col cols="12" :md="selectedRow && selectedRow.is_latex ? 6 : 12">
            <VueTip
              @onInit="(editor) => (vueTipEditor = editor)"
              :initial="selectedCell"
              @changed="handleVueTipTextUpdate"
              placeholder="Type here..."
              variant="default"
              :minRows="6"
            />
          </v-col>
          <v-col cols="12" md="6" v-if="selectedRow && selectedRow.is_latex">
            <v-card-text class="white black--text py-8">
              <latex-html :html="selectedCell" :isLatex="true" />
            </v-card-text>
          </v-col>
        </v-row>
      </template>
    </WysiwygEditorDialog>
    <QuestionTagsSelector
      :tags="tags"
      :row="selectedRow"
      :show="showQuestionTagsSelector"
      @opened="(trueFalse) => (showQuestionTagsSelector = trueFalse)"
    ></QuestionTagsSelector>
    <latex-editor-dialog
      :show="showLatexEditor"
      @closed="(val) => (showLatexEditor = val)"
      @changed="(val) => (selectedCell = val)"
      :initial="selectedCell"
    />

    <question-preview-dialog
      :show="showPreview"
      @closed="(val) => (showPreview = val)"
      :item="selectedRow"
    >
    </question-preview-dialog>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ColumnDefs } from "../store/ColumnDefs";
import CtxMenu from "./CtxMenu";
import axios from "axios";
import WysiwygEditorDialog from "./WysiwygEditorDialog.vue";
import QuestionPreviewDialog from "./QuestionPreviewDialog";
import LatexEditorDialog from "../../prep/components/Questions/LatexEditorDialog";
import QuestionTagsSelector from "./QuestionTagsSelector";
import LatexHtml from "@/components/LatexHtml";
import "./style.css";
import VueTip from "@/components/VueTip";

export default {
  name: "dashboard-grid-segment-questions",
  components: {
    WysiwygEditorDialog,
    QuestionTagsSelector,
    QuestionPreviewDialog,
    LatexEditorDialog,
    LatexHtml,
    VueTip,
  },
  data() {
    return {
      vueTipEditor: null,
      showPreview: null,
      showLatexEditor: null,
      showWysiwygEditor: null,
      showQuestionTagsSelector: null,
      loading: false,
      columnDefs: [],
      fontSize: 14,
      busy: false,
      userReservedKeys: [
        "d",
        "r",
        "l",
        "e",
        "q",
        "i",
        "j",
        "m",
        ".",
        "`",
        "[",
        "]",
        "p",
        ",",
        ".",
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
      rows: "dashboard/grid/rows",
      selectedRow: "dashboard/grid/selectedRow",
      segment: "dashboard/grid/segment",
      tags: "dashboard/grid/tags",
      richTextFields: "dashboard/grid/richTextFields",
    }),
    modifiedRows() {
      return this.rows.filter((row) => row.modified);
    },
    selectedRowIndex() {
      return this.$store.state.dashboard.grid.selectedRowIndex;
    },
    selectedColIndex() {
      return this.$store.state.dashboard.grid.selectedColIndex;
    },
    selectedRowField() {
      return this.$store.state.dashboard.grid.selectedRowField;
    },
    selectedCell: {
      get() {
        return this.$store.getters["dashboard/grid/selectedCell"];
      },
      set(val) {
        return this.$store.dispatch("dashboard/grid/updateSelectedCell", val);
      },
    },
    colViewMode: {
      get() {
        return this.$store.getters["dashboard/grid/colViewMode"];
      },
      set(val) {
        this.$store.dispatch("dashboard/grid/setColViewMode", val);
      },
    },
    gridStyle() {
      let mStyle = `font-size: ${this.fontSize}px;`;
      if (false) {
        // enableSpellCheck
        mStyle += `height: calc(100vh - 6rem);`;
      } else {
        mStyle += `height: calc(100vh - 12.5rem);border:0px;`;
      }
      return mStyle;
    },
  },
  async beforeCreate() {
    this.columnDefs = await ColumnDefs({
      colViewMode: this.colViewMode,
    });
  },
  watch: {
    showWysiwygEditor(val) {
      if (this.vueTipEditor && val) {
        setTimeout(() => {
          this.vueTipEditor.focus("end");
        }, 100);
      }
      this.handleFocusBlur(val);
    },
    showLatexEditor(val) {
      this.handleFocusBlur(val);
    },
    showPreview(val) {
      // this.handleFocusBlur(val);
    },
    rows() {
      if (!this.grid) {
        this.grid = this.$refs.grid;
      }
    },
    async colViewMode(val) {
      this.columnDefs = await ColumnDefs({
        colViewMode: this.colViewMode,
      });
    },
  },
  methods: {
    ...mapActions({
      action_publish: "dashboard/grid/publish",
      action_undoRemoveRow: "dashboard/grid/undoRemoveRow",
      action_RemoveRow: "dashboard/grid/removeRow",
      action_refresh: "dashboard/grid/refresh",
      action_addRow: "dashboard/grid/addRow",
      action_duplicate: "dashboard/grid/duplicateRow",
    }),
    handleVueTipTextUpdate(val) {
      this.selectedCell = val;
      setTimeout(() => {
        if (this.showWysiwygEditor) {
          this.$store.dispatch("dashboard/grid/modified", {
            row: this.selectedRow,
          });
        }
      }, 0);
    },
    handleFocusBlur(val) {
      this.$refs.grid.focused = !val;
      if (!val) {
        setTimeout(() => {
          this.selectRow(this.selectedRowIndex);
        }, 0);
      } else {
        this.$store.dispatch("dashboard/grid/pushTextIntoHistory", {
          rowIndex: this.selectedRowIndex,
          colField: this.selectedRowField,
          value: this.selectedCell,
        });
      }
    },
    //
    handleKeyDown(e) {
      const canModify = !this.showPreview;
      const isFocused = this.$refs.grid ? this.$refs.grid.focused : false;
      const isControl = e.metaKey || e.ctrlKey;
      const isShift = e.shiftKey;
      const isAlt = e.altKey;
      const key = e.key;

      if (isFocused && isControl && this.selectedRow) {
        if (this.userReservedKeys.includes(key)) {
          e.preventDefault();
          e.stopPropagation();
        }
        // if (this.busy) return;
        // this.busy = true;
        switch (key.toLowerCase()) {
          case "d":
            if (!canModify) return;
            console.log("MAKE DUPLICATE");
            this.action_duplicate(this.selectedRowIndex);
            break;

          case "q":
            if (!canModify) return;
            console.log("DELETE ROW");
            this.action_RemoveRow(this.selectedRowIndex);
            break;

          case "z":
            if (!canModify) return;
            console.log("UNDO DELETE ROW");
            this.action_undoRemoveRow();
            break;

          case "e":
            if (!canModify) return;
            console.log("SHOW EDITOR");
            if (
              this.selectedRowField &&
              this.richTextFields.includes(this.selectedRowField)
            ) {
              this.showWysiwygEditor = true;
            }
            break;

          case "m":
            if (!canModify) return;
            console.log("SHOW LATEX");
            if (
              this.selectedRowField &&
              this.richTextFields.includes(this.selectedRowField)
            ) {
              this.showLatexEditor = true;
            }
            break;

          case "`":
            if (!canModify) return;
            console.log("ADD ROW AT BOTTOM");
            this.action_addRow();
            break;

          case "[":
            if (!canModify) return;
            console.log("MOVE ROW UP");
            this.$store.dispatch(`dashboard/grid/moveRowItem`, {
              rowIndex: this.selectedRowIndex,
              indexOffset: -1,
            });
            this.selectRow(this.selectedRowIndex - 1);
            break;

          case "]":
            if (!canModify) return;
            console.log("MOVE ROW DOWN");
            this.$store.dispatch(`dashboard/grid/moveRowItem`, {
              rowIndex: this.selectedRowIndex,
              indexOffset: 1,
            });
            this.selectRow(this.selectedRowIndex + 1);
            break;

          case "j":
            if (!canModify) return;
            console.log("INSERT ROW DOWN");
            this.$store.dispatch(`dashboard/grid/addRowAtIndex`, {
              atIndex: this.selectedRowIndex + 1,
              clickedIndex: this.selectedRowIndex,
            });
            this.selectRow(this.selectedRowIndex + 1);
            break;

          case "i":
            if (!canModify) return;
            console.log("INSERT ROW UP");
            this.$store.dispatch(`dashboard/grid/addRowAtIndex`, {
              atIndex: this.selectedRowIndex,
              clickedIndex: this.selectedRowIndex,
            });
            this.selectRow(this.selectedRowIndex);
            break;

          case "p":
            if (!canModify) return;
            console.log("SHOW PREVIEW");
            this.showPreview = true;
            break;

          case ",":
            console.log("PREVIOUS ROW");
            if (this.selectedRowIndex > 0) {
              this.selectRow(this.selectedRowIndex - 1);
            }
            break;

          case ".":
            console.log("NEXT ROW");
            if (this.selectedRowIndex < this.rows.length - 1) {
              this.selectRow(this.selectedRowIndex + 1);
            }
            break;

          default:
            break;
        }
        // setTimeout(() => {
        //   this.busy = false;
        // }, 100);
        return false;
      } else {
        // console.log("key down...", e);
      }
    },
    scrollToIndex(idx) {
      let rowHeight = this.fontSize * 2;
      let offset = rowHeight * idx;
      let tableDiv = document.querySelector("table.grid-table tbody");
      tableDiv.scrollTop = offset - screen.height / 3;
      // tableDiv.scrollTop = offset - rowHeight * 4;
    },
    scrollToRow(row) {
      let idx = row.sn ? row.sn - 1 : 0;
      this.scrollToIndex(idx);
    },
    selectRow(rowIdx = 0) {
      console.log("this.selectedRowIndex", rowIdx, this.selectedRowIndex);
      setTimeout(() => {
        this.grid
          .selectCell(rowIdx, this.selectedColIndex ? this.selectedColIndex : 0)
          .then((rr) => {})
          .catch((err) => {});
      }, 0);
    },
    cellUpdated(e) {
      return this.$store.dispatch("dashboard/grid/cellUpdated", e);
    },
    async rowSelected(e) {
      await this.$store.dispatch("dashboard/grid/rowSelected", e);
    },
    onContextMenu(e) {
      e.$event.preventDefault();

      if (e.column && e.column.field && e.column.field === "tags") {
        this.showQuestionTagsSelector = true;
        // open tag selector here
        return;
      }

      return setTimeout(() => {
        if (
          e.column &&
          e.column.field &&
          ![
            "sn",
            "toggle_active",
            "toggle_is_latex",
            "toggle_has_options",
            "tags",
            "select_prep_subject_id",
            "language",
            "toggle_language",
          ].includes(e.column.field)
        ) {
          CtxMenu(e, this);
        }
        return this.$store.dispatch("dashboard/grid/onContextMenu", e);
      }, 0);
    },
    onKeyUp(e) {
      //console.log("..... KEY UP....", e);
      return this.$store.dispatch("dashboard/grid/onKeyUp", e);
    },
    fetchSegmentWithQuestions() {
      this.loading = true;
      return this.$store
        .dispatch("dashboard/grid/fetchRows", {
          segment_id: this.$route.params.id,
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  created() {},
  mounted() {
    this.grid = this.$refs.grid;

    this.fetchSegmentWithQuestions();

    window.onbeforeunload = () => {
      return "Are you sure you want to leave? Think of the kittens!";
    };

    document.addEventListener("keydown", this.handleKeyDown);
  },
  destroyed() {
    document.removeEventListener("keydown", () => {});
    window.onbeforeunload = null;
  },
};
</script>
