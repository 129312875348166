import store from "@/store";
import bus from "@/bus";
import { getRandomString } from "@/helpers/utils";

const storePath = "dashboard/grid";

export default (evt, ctx) => {
  let e = evt.$event;
  e.preventDefault();

  const randomStr = "-" + getRandomString(5);

  let rows = store.getters[`${storePath}/rows`];

  let row = evt.row;
  const column = evt.column;
  const colField = column.field;
  const rowIndex = evt.rowIndex;

  let menu = document.createElement("div");
  menu.id = "ctxmenu" + randomStr;
  menu.classList.add("ctxmenu", "noselect");

  menu.onmouseleave = () => (menu.outerHTML = "");
  menu.onclick = () => (menu.outerHTML = "");
  menu.onblur = () => (menu.outerHTML = "");

  const elmIDMoveUp = "ctxMoveUp" + randomStr;
  const elmIDMoveDown = "ctxMoveDown" + randomStr;
  const elmIDInsertAbove = "ctxInsertAbove" + randomStr;
  const elmIDInsertBelow = "ctxInsertBelow" + randomStr;
  const elmIDUndo = "ctxUndo" + randomStr;
  const elmIDRedo = "ctxRedo" + randomStr;
  const elmIDRichText = "ctxRichText" + randomStr;
  // const elmIDLatex = "ctxLatex" + randomStr;
  const elmIDDuplicate = "ctxDuplicate" + randomStr;
  const elmIDDelete = "ctxDelete" + randomStr;

  //    <p id="${elmIDRedo}" >⟳ Redo text change</p><p class="separator"></p>

  // <p id="${elmIDLatex}" >∑ Edit Math<span style="float:right;">Ctrl + M</span></p>

  menu.innerHTML = `
    <div class="subheader" >
      Row Number ${row.sn}
      <span style="float:right;">✖</span>
    </div>
    <p id="${elmIDMoveUp}" >▲ Move up <span style="float:right;">Ctrl + [</span></p>
    <p id="${elmIDMoveDown}" >▼ Move down<span style="float:right;">Ctrl + ]</span></p>
    <p class="separator"></p>
    <p id="${elmIDDuplicate}">⇄ Duplicate<span style="float:right;">Ctrl + D</span></p>
    <p class="separator"></p>
    <p id="${elmIDInsertAbove}" >↱ Insert row above<span style="float:right;">Ctrl + I</span></p>
    <p id="${elmIDInsertBelow}" >↳ Insert row below<span style="float:right;">Ctrl + J</span></p>
    <p class="separator"></p>
    <p id="${elmIDRichText}" >✎ Edit Text<span style="float:right;">Ctrl + E</span></p>
    <p class="separator"></p>
    <p id="${elmIDUndo}" >⟲ Undo text change</p>
    <p id="${elmIDDelete}">🗑 Delete<span style="float:right;">Ctrl + Q</span></p>
  `;

  document.body.appendChild(menu);

  let menuPosY = e.pageY;
  let menuPosX = e.pageX;

  if (window.innerHeight < e.pageY + menu.clientHeight) {
    menuPosY = e.pageY - menu.clientHeight;
    menuPosY = menuPosY + 50;
  } else {
    menuPosY = menuPosY - 50;
  }
  if (window.innerWidth < e.pageX + menu.clientWidth) {
    menuPosX = e.pageX - menu.clientWidth;
  }
  menu.style = `top:${menuPosY}px;left:${menuPosX - 50}px`;
  // position menu end

  let elmMoveUp = document.getElementById(elmIDMoveUp);
  let elmMoveDown = document.getElementById(elmIDMoveDown);
  let elmInsertAbove = document.getElementById(elmIDInsertAbove);
  let elmInsertBelow = document.getElementById(elmIDInsertBelow);
  let elmUndo = document.getElementById(elmIDUndo);
  // let elmRedo = document.getElementById(elmIDRedo);
  let elmRichText = document.getElementById(elmIDRichText);
  // let elmLatex = document.getElementById(elmIDLatex);
  let elmDuplicate = document.getElementById(elmIDDuplicate);
  let elmDelete = document.getElementById(elmIDDelete);

  elmMoveUp.onclick = () => {
    store.dispatch(`${storePath}/moveRowItem`, {
      rowIndex,
      indexOffset: -1,
    });
  };

  elmMoveDown.onclick = () => {
    store.dispatch(`${storePath}/moveRowItem`, {
      rowIndex,
      indexOffset: 1,
    });
  };

  // this code block is working fine, uncomment if you like.
  elmInsertAbove.onclick = () => {
    store.dispatch(`${storePath}/addRowAtIndex`, {
      atIndex: rowIndex,
      clickedIndex: rowIndex,
    });
  };
  elmInsertBelow.onclick = () => {
    store.dispatch(`${storePath}/addRowAtIndex`, {
      atIndex: rowIndex + 1,
      clickedIndex: rowIndex,
    });
    bus.$emit("selectRow", row.sn + 1);
  };

  elmDelete.onclick = () => {
    store.dispatch(`${storePath}/removeRow`, rowIndex);
  };
  elmDuplicate.onclick = () => {
    store.dispatch(`${storePath}/duplicateRow`, rowIndex);
  };

  // if (row.historyPointer < row.history.length - 1 && row.historyPointer > 0) {
  //   // can undo and redo
  // } else if(){

  // }

  // elmUndo.setAttribute("style", "color: grey;");
  // elmRedo.setAttribute("style", "color: grey;");

  elmUndo.onclick = (e) => {
    store.dispatch(`${storePath}/undoTextChange`, { rowIndex, colField });
  };
  // elmRedo.onclick = (e) => {
  //   store.dispatch(`${storePath}/undoTextChange`, { row, column });
  // };

  elmRichText.onclick = (e) => {
    ctx.showWysiwygEditor = true;
  };
  // elmLatex.onclick = (e) => {
  //   ctx.showLatexEditor = true;
  // };
};
