<template>
  <v-dialog
    :fullscreen="fullscreen"
    v-model="dialog"
    max-width="1000"
    transition="dialog-transition"
    class="pa-0 ma-0"
  >
    <v-card tile class="pa-0 ma-0">
      <v-card-title class="px-2 py-2 indigo accent-4 white--text">
        Q {{ item ? item.sn : "??" }}.
        <v-spacer></v-spacer>
        <span class="mr-1">Prev -</span><v-chip small>ctrl + ,</v-chip>
        <span class="ml-2 mr-1">Next -</span><v-chip small>ctrl + .</v-chip>
        <v-spacer></v-spacer>
        <v-btn small class="ml-1" icon dark @click="fullscreen = !fullscreen">
          <v-icon v-if="fullscreen">mdi-fullscreen-exit</v-icon>
          <v-icon v-else>mdi-fullscreen</v-icon>
        </v-btn>

        <v-btn small class="ml-1" icon dark @click="dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-row no-gutters dense>
        <v-col cols="12" md="6">
          <question-card
            touchless
            @click.stop="() => {}"
            locale="en"
            :question="item"
            :idx="-1"
            :currentQuestionId="0"
            :withAnswer="true"
          />
        </v-col>
        <v-col cols="12" md="6">
          <question-card
            touchless
            @click.stop="() => {}"
            locale="hi"
            :question="item"
            :idx="-1"
            :currentQuestionId="0"
            :withAnswer="true"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import QuestionCard from "@/components/QuestionCard";

export default {
  name: "question-preview-dialog",
  props: ["item", "show"],
  components: {
    QuestionCard,
  },
  data() {
    return {
      dialog: null,
      locale: null,
      fullscreen: false,
      selectedProp: "",
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
      this.locale = this.$store.getters.getCurrLocale;
    },
    dialog(val) {
      this.$emit("closed", val);
    },
  },
  created() {
    this.locale = this.$store.getters.getCurrLocale;
    this.dialog = this.show;
  },
  methods: {
    toggleLocale() {
      if (this.locale == "hi") {
        this.locale = "en";
      } else {
        this.locale = "hi";
      }
    },
  },
};
</script>

<style>
</style>